@import "src/sass/variables";

.partners-slider {
  width: 100%;
  margin-bottom: 150px;

  &_mobile {
    margin-bottom: 70px;
  }

  .partners-slider-swiper {
    overflow: unset;
  }

  .swiper-slide {
    width: 100% !important;
    transform: unset !important;
  }

  .bullets {
    display: flex;
    position: absolute;
    bottom: -50px;
    padding: 15px 0;
    width: 100%;
    justify-content: center;
    align-items: center;

    .swiper-pagination .swiper-pagination-bullet {
      display: block;
      height: 10px;
      width: 10px;
      border-radius: 100%;
      border: 1px solid $color-gallery;

      &.swiper-pagination-bullet-active {
        border-color: $color-black;
      }

      & + .swiper-pagination-bullet {
        margin-left: 20px;
      }
    }
  }
}

.partners-slider-title {
  font-size: 26px;
  font-weight: 700;
  line-height: 140%;
  max-width: 1600px;
  width: 84.4%;
  margin: 0 auto 50px;

  &_mobile {
    margin-bottom: 20px;
  }
}

.partners-slider-slide {
  display: grid;
  height: 100%;
  grid-template: auto / repeat(6, 1fr);
  grid-gap: 20px;
  justify-content: center;
  width: 84.4%;
  max-width: 1600px;
  margin: 0 auto;

  &_mobile {
    grid-template: repeat(3, 1fr) / repeat(2, 1fr);

    @media screen and (min-width: 460px) {
      grid-template: auto / repeat(3, 1fr);
    }
  }
}

.partners-slide-container-image {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.partners-slide-image {
  width: 100%;
}

.partners-slider-button {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 60px;
  height: 60px;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 100px;
  background-color: rgba($color-white, 1);
  cursor: pointer;
  transition: background 0.2s;
  z-index: 1;
  box-shadow: 0 4px 16px rgba($color-shadow-dark, 0.15);

  &[aria-disabled="true"] {
    opacity: 0.3;
    pointer-events: none;
  }

  &:hover {
    background-color: rgba($color-white, 0.3);
  }

  &:active {
    transition: none;
    background-color: rgba($color-white, 1);
  }
}

.partners-slider-button-next {
  right: 20px;
  z-index: 4;
}

.partners-slider-button-prev {
  left: 20px;
}



